<template>
  <v-card
    flat
    :loading="!facebookLoaded"
  >
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text class="flex-column">
      <m-widget-container-form v-model="value" />
      <v-text-field
        v-model="value.feedTitle"
        outlined
        label="Title"
        persistent-hint
        hint="You can leave this empty if you don't want to show a title."
      />
      <field-card label="Design & Display">
        <v-radio-group
          v-model="value.cols"
          row
          required
        >
          <template #label>
            <div>How many columns should the widget display?</div>
          </template>
          <v-radio
            v-for="n in 6"
            :key="n"
            :label="`${n}`"
            :value="n"
          />
        </v-radio-group>
        <v-switch
          v-model="value.marqueeCaptions"
          label="Show Captions Marquee"
        />
      </field-card>
      <v-divider />
      <field-card label="Instagram Feed">
        <template v-if="!value.accessToken">
          <v-subheader>
            Please click to connect your facebook account to get started.
          </v-subheader>
          <v-btn
            color="primary"
            text
            :loading="gettingToken"
            @click="login"
          >
            Connect with Facebook
          </v-btn>
        </template>
        <v-alert
          v-else-if="!anyBusinessPage"
          text
          prominent
          type="error"
          icon="mdi-exclamation"
        >
          <v-row
            align="center"
            no-gutters
          >
            <v-col class="grow mr-4">
              <div>You currently do not have an instagram account connected to any of your facebook pages. Please follow the instructions to connect your account by clicking the link on the right.</div>
            </v-col>
            <v-col class="shrink">
              <v-btn
                color="info"
                href="https://www.facebook.com/business/help/898752960195806"
                target="_blank"
                outlined
              >
                Instructions
              </v-btn>
            </v-col>
          </v-row>
          <v-divider
            class="my-4 info"
            style="opacity: 0.5"
          />
          <v-row>
            <v-col class="grow mr-4">
              <div>After you're finished connecting your instagram account please click on the verify button on the right to continue.</div>
            </v-col>
            <v-col class="shrink d-flex flex-column">
              <v-btn
                color="success"
                :loading="gettingInstagramAccounts"
                @click="getInstagramAccounts"
              >
                Verify
              </v-btn>
              <v-btn
                class="mt-3"
                color="error"
                outlined
                :loading="discardingToken"
                @click="logout"
              >
                Disconnect
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <template v-else>
          <v-alert
            text
            prominent
            type="success"
            icon="mdi-check"
          >
            <v-row align="center">
              <v-col class="grow">
                You are connected.
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="primary"
                  outlined
                  :loading="discardingToken"
                  @click="logout"
                >
                  Disconnect
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-radio-group
            v-model="value.feedType"
          >
            <template #label>
              <div>Which type of feed would you like to show?</div>
            </template>
            <v-radio
              label="Hashtag Feed"
              value="hashtag"
            />
            <v-radio
              label="Business Page Feed"
              value="business_page"
              disabled
            />
          </v-radio-group>
          <template v-if="value.feedType === 'hashtag'">
            <v-text-field
              v-model="value.hashtag.tag"
              outlined
              label="Hashtag"
            />
            <v-select
              v-model="value.hashtag.type"
              outlined
              :items="hashtagFeedTypes"
              label="Feed Type"
            />
          </template>
          <template v-if="value.feedType === 'business_page'">
            <v-radio-group
              v-model="value.businessPageId"
            >
              <template #label>
                <div>Please select the page connected to your account</div>
              </template>
              <v-radio
                v-for="page in value.businessPages"
                :key="page.pageId"
                :label="`${page.name}${!page.instagramBusinessAccountId ? ': No Instagram account connected' : ''}`"
                :value="page.pageId"
                :disabled="!page.instagramBusinessAccountId"
              />
            </v-radio-group>
          </template>
        </template>
      </field-card>
    </v-card-text>
    <v-snackbar
      v-model="noInstagramAccounts"
      color="error"
    >
      No instagram accounts were found connected to this facebook account.
      <v-btn
        icon
        @click="noInstagramAccounts = false"
      >
        <v-icon v-text="'mdi-close'" />
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
/*
  In order to test facebook locally, change the status of the facebook app to dev mode, serve the localhost through ngrok tunnel as https and add the https url to authorized redirect urls, if needed also to App Domains in main settings
 */

import axios from 'axios'
export default {
  name: 'MInstagramFeedForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    facebookLoaded: false,
    gettingToken: false,
    discardingToken: false,
    noInstagramAccounts: false,
    gettingInstagramAccounts: false,
    appId: '419846155395703',
    appSecret: '84086aeb1664eea2fa0ae05b4c18ed41',
    hashtagFeedTypes: [
      'top_media',
      'recent_media'
    ]
  }),
  computed: {
    anyBusinessPage() {
      return this.value.businessPages.find(x => !!x.instagramBusinessAccountId)
    }
  },
  beforeMount() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: this.appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v4.0'
      })
      this.getLoginStatus()
    }
    const fjs = document.getElementsByTagName('script')[0]
    if (!document.getElementById('fbsdk')) {
      const js = document.createElement('script')
      js.id = 'fbsdk'
      js.async = true
      js.defer = true
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    } else {
      this.getLoginStatus()
    }
  },
  methods: {
    getLoginStatus() {
      window.FB.getLoginStatus((response) => {
        if (response.status !== 'connected') {
          this.value.accessToken = null
        }
      })
      this.facebookLoaded = true
    },
    login() {
      this.gettingToken = true
      window.FB.login(async(response) => {
        if (response.authResponse) {
          const shortLivedToken = response.authResponse.accessToken
          await this.getLongLivedToken(shortLivedToken)
          await this.getInstagramAccounts()
          this.gettingToken = false
        } else {
          this.gettingToken = false
          this.error = { message: 'You cancelled the facebook login process, or did not authorize Mammut OS. Please try again.' }
        }
      }, { scope: 'public_profile, email, instagram_basic, manage_pages' })
    },
    async getLongLivedToken(shortLivedToken) {
      const { data } = await axios.get(`https://graph.facebook.com/oauth/access_token?client_id=${this.appId}&client_secret=${this.appSecret}&grant_type=fb_exchange_token&fb_exchange_token=${shortLivedToken}`)
      this.value.accessToken = data.access_token
    },
    async getInstagramAccounts() {
      this.gettingInstagramAccounts = true
      const { data } = await axios.get(`https://graph.facebook.com/me/accounts?access_token=${this.value.accessToken}`)
      const businessPages = await Promise.all(data.data.map(async(x) => {
        const { data } = await axios.get(`https://graph.facebook.com/v4.0/${x.id}?fields=instagram_business_account&access_token=${x.access_token}`)
        return {
          pageId: x.id,
          name: x.name,
          accessToken: x.access_token,
          instagramBusinessAccountId: data.instagram_business_account ? data.instagram_business_account.id : null
        }
      }))
      this.gettingInstagramAccounts = false
      this.value.businessPages = businessPages
      this.noInstagramAccounts = businessPages.length === 0
    },
    logout() {
      this.discardingToken = true
      window.FB.logout(() => {
        this.value.accessToken = undefined
        this.discardingToken = false
        return false
      })
    }
  }
}
</script>
